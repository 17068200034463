<script>
import searchBar from '@components/searchBar'
import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
import PaginationRow from "@components/global/PaginationRow";


export default {
  components: {PaginationRow, searchBar},
  page: {title: 'Συνταγές'},
  mixins: [PaginatedDataMixin],

  data() {
    return {
      recipeModal: {
        name: '',
        error: null,
        creating: false
      }
    }
  },

  created(){
    this.api.url = '/recipes/'; // set api url.
    this.api.query.limit = 5;
    this.getData();
  },

  methods: {
    deleteRecipe(recipe){
      let message = {
        title: 'Θέλετε σίγουρα να διαγράψετε τη συνταγή αυτή; ',
        body: 'Η ενέργεια είναι μη αναστρέψιμη. Εάν πατήσετε διαγραφή, δεν θα μπορέσετε να επαναφέρετε αυτή τη συνταγή, και θα διαγραφεί και το αντίστοιχο τρόφιμο.'
      };
      this.$dialog.confirm(message, {
        loader: true,
        okText: 'Διαγραφή',
        cancelText: 'Ακύρωση',
        backdropClose: true
      }).then((dialog) => {
        this.$axios.delete(`/recipes/${recipe.id}`).then(async result => {
          dialog.close();
          await this.$store.dispatch('food/deleteFood',  recipe.food.id);
          this.$notify({group: 'athlisis-notifications', type: 'success', title: this.$t('success.title'), text: 'Το πρότυπο γεύμα διαγράφηκε επιτυχώς!'});
          this.getData();

        }).catch(e => {
          this.$notify({group: 'athlisis-notifications', type: 'error', title: this.$t('errors.title'), text: 'Η διαγραφή απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
        });
      }).catch(()=>{});
    },

    viewRecipe(id){
      this.$router.push('/recipes/'+id);
    },

    openCreateRecipeModal(){
      this.$refs['create-recipe-modal'].show();
    },
    createRecipe(){

      if(this.recipeModal.name === ''){
        this.recipeModal.error = 'Παρακαλώ εισάγετε ένα όνομα για τη συνταγή';
        return;
      }

      this.recipeModal.error = null;
      this.recipeModal.creating = true;
      this.$axios.post(`/recipes`, {name: this.recipeModal.name}).then(async res => {

        // create food
        await this.$store.dispatch('food/addNewFood', res.data.food);

        setTimeout(() => {
          this.recipeModal.creating = false;
          this.$router.push('/recipes/'+res.data.recipe.id);
        }, 1000);

      }).catch(e =>{
        this.recipeModal.creating = false;
        this.$notify({group: 'athlisis-notifications', type: 'error', title: this.$t('errors.title'), text: 'Η δημιουργία απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
      })
    }
  }
}

</script>
<template>
  <div id="recipe-list-layout">
    <div id="recipe-list-container">
      <searchBar>
        <template v-slot:forms>
          <form class="form-inline">
            <div class="form-group"><label class="sr-only">{{ $t('search') }}</label>
              <b-form-input v-model="api.filters.search" type="search" class="form-control" :placeholder="$t('searchPlaceholder')" debounce="400" />
            </div>
          </form>
        </template>
        <template v-slot:buttons>
          <div class="text-lg-right mt-3 mt-lg-0">
            <button type="button" class="btn btn-primary mr-1" @click="openCreateRecipeModal"><i class="fe-plus-circle"></i> Προσθήκη νέας συνταγής</button>
          </div>
        </template>
      </searchBar>

      <div v-if="api.rows.length === 0 && !api.loading && api.page === 1">
        <div class="card dp-not-found">
          <div class="card-body text-center">
            <h2 class="headline-not-found">ΔΕΝ ΒΡΕΘΗΚΑΝ ΣΥΝΤΑΓΕΣ</h2>
            <p>Δεν έχετε αποθηκεύσει ακόμα κάποια συνταγή.</p>
            <div class="pt-3"><img src="@assets/images/undraw-empty.svg" class="img-elements-not-found" alt="" height="250"/></div>

          </div>
        </div>
      </div>

      <div v-if="api.rows.length > 0" class="row">
        <div class="col-md-12">
          <div v-for="recipe of api.rows" :key="recipe.id">
            <b-card class="shadow-sm diet-plan-item" @click="viewRecipe(recipe.id)">
              <b-card-text>
                <div class="row">
                  <div class="col-md-2">
                    <span class="text-muted">Όνομα</span>  <br>
                    <span class="dp-name">
                                              {{recipe.name}}
                                          </span>
                  </div>
                  <div v-if="recipe.food" class="col-md-2">
                    <span class="text-muted">Υδατ.</span>  <br>
                    <span class="dp-name">
                                              {{(recipe.food.carbohydrates).toFixed(2)}} gr
                                          </span>
                  </div>
                  <div  v-if="recipe.food" class="col-md-2">
                    <span class="text-muted">Πρωτ.</span>  <br>
                    <span class="dp-name">
                                              {{(recipe.food.proteins).toFixed(2)}} gr
                                          </span>
                  </div>
                  <div v-if="recipe.food" class="col-md-2">
                    <span class="text-muted">Λίπη</span>  <br>
                    <span class="dp-name">
                                              {{(recipe.food.fats).toFixed(2)}} gr
                                          </span>
                  </div>
                  <div v-if="recipe.food" class="col-md-2">
                    <span class="text-muted">Ενέργεια</span>  <br>
                    <span class="dp-name">
                                              {{(recipe.food.kcal).toFixed(2)}} kcal
                                          </span>
                  </div>
                  <div class="col-md-2">
                    <span class="text-muted">Ενέργειες</span>  <br>
                    <span class="dp-name">
                                          <b-button variant="primary" class="btn-xs align-middle m-1" @click="viewRecipe(recipe.id)"><i class="fe-eye"></i></b-button>

                                          <b-button variant="danger" class="btn-xs align-middle m-1"
                                                    @click.stop="deleteRecipe(recipe)"><i class="fas fa-trash"></i></b-button>
                                          </span>
                  </div>
                </div>


              </b-card-text>
            </b-card>
          </div>

          <pagination-row :api="api" @fetchData="getData"></pagination-row>
        </div>
      </div>


      <b-modal id="create-recipe-modal" ref="create-recipe-modal" size="small" title="Δημιουργία συνταγής" scrollable hide-footer body-class="p-3">
        <b-overlay :show="recipeModal.creating" rounded="sm">
          <div class="row">
            <div class="col-md-12">
              <b-form-input v-model="recipeModal.name" placeholder="Όνομα συνταγής"></b-form-input>
            </div>
            <div class="col-md-12 mt-3">
              <b-button variant="primary" @click="createRecipe" class="w-100">Δημιουργία συνταγής</b-button>
              <b-alert v-if="recipeModal.error" class="mt-2" variant="danger" :show="true">{{recipeModal.error}}</b-alert>
            </div>
          </div>
        </b-overlay>
      </b-modal>
  </div>
  </div>
</template>
